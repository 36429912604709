import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';

import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';
import {
  DidomiFiltersBar,
  DidomiIcon,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableTh,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { useQueryClient } from 'react-query';
import { SvlEmptyData, SvlVendorTableRowOld } from '@components';
import { usePaginatedFilteredItems, useTableState, usePropertyReportVendors } from '@hooks';
import { RootProperty, VendorsSortableField, Vendor } from '@types';
import { lastExploitableReport, defineVendorType, mapAndSetExploitableReportsIds, mapVendorsBasedOnIab, mapVendorsBaseFields, handleFilteringVendors } from '@utils';

const SEARCH_FIELDS = [{ field: 'nameOrKey' }];
const vendorTableColHeaderName = [
  { name: 'Vendor name', sortId: 'nameOrKey' },
  { name: 'Vendor type', sortId: null },
  { name: ' ', sortId: null },
];
const filter = {
  type: {
    placeholder: 'Vendor type',
    value: null,
    multi: true,
    options: [
      { value: 'unmatched', label: 'Unmatched' },
      { value: 'custom', label: 'Custom' },
      { value: 'iab', label: 'IAB' },
      { value: 'atp', label: 'Google ATP' },
    ],
  },
};
interface SvlVendorsListProps {
  properties: RootProperty[];
  activeStep: Number;
  setActiveStep?: Function;
}
const SvlVendorsListOld = ({ properties, activeStep, setActiveStep }: SvlVendorsListProps) => {
  const [reportIds, setReportIds] = useState(null);
  const { isLoading: vendorsLoading, data: vendorsFromReportIds, error } = usePropertyReportVendors({ ids: reportIds });
  const [selectedVendorType, setSelectedVendorType] = useState([]) as any;
  const queryClient = useQueryClient();

  let [searchParams, setSearchParams] = useSearchParams();
  const doesRefresh = searchParams.get('refresh');
  useEffect(() => {
    /* istanbul ignore next */
    if (!!doesRefresh === true) {
      queryClient.invalidateQueries({ queryKey: ['property-report-vendors'] });
      searchParams.delete('refresh');
      setSearchParams(searchParams);
    }
  }, [queryClient, searchParams, doesRefresh, setSearchParams]);

  useEffect(() => {
    return mapAndSetExploitableReportsIds({ properties, lastExploitableReport, activeStep, setReportIds });
  }, [properties, activeStep]);

  const filterByVendorType = (vendors, value) => {
    if (!value.length || null) {
      return vendors;
    }
    return vendors.filter(elm => value.includes(elm.filterType));
  };
  const vendors = useMemo(() => {
    return mapVendorsBasedOnIab({ vendorsFromReportIds });
  }, [vendorsFromReportIds]);

  const filters = useMemo(() => {
    return [{ filter: filterByVendorType, value: selectedVendorType }];
  }, [selectedVendorType]);

  const aggregatedVendorTableData = useMemo(() => {
    return mapVendorsBaseFields({ vendorsFromApi: vendors, defineVendorType: defineVendorType });
  }, [vendors]);
  const {
    limit,
    page: currPage,
    search,
    changeLimit: setLimit,
    changeSearch: setSearch,
    changePage: setCurrPage,
    changeSorting,
    sortConfig,
  } = useTableState<VendorsSortableField>({
    defaultLimit: 20,
    defaultFilters: {},
  });
  const { data: filteredVendors, total: vendorsTotal } = usePaginatedFilteredItems<Vendor, VendorsSortableField>(aggregatedVendorTableData, {
    search,
    searchFields: SEARCH_FIELDS,
    limit: limit,
    page: currPage,
    filters: filters,
    sortConfig,
  });
  useEffect(() => {
    if (Math.ceil(vendorsTotal / limit) < currPage) filteredVendors && setCurrPage({ detail: { page: 1 } } as CustomEvent);
  }, [filteredVendors, setCurrPage, vendorsTotal, limit, currPage]);
  const handleFilterChange = (newFilterChange: DidomiFiltersBarCustomEvent<{ key: string; newValue: string }>) => {
    handleFilteringVendors({ newFilterChange, handleFiltersReset, setSelectedVendorType });
  };
  const handleFiltersReset = () => {
    setSelectedVendorType('');
    setSearch('');
  };
  const handleSearchChange = (e: CustomEvent<string>) => setSearch(e.detail);

  return (
    <div className="pt-l mb-l">
      {(!vendorsLoading && !!error) || (!vendorsLoading && aggregatedVendorTableData?.length === 0) ? (
        <SvlEmptyData error={!!error} noIab={true} setActiveStep={setActiveStep}></SvlEmptyData>
      ) : (
        <DidomiSkeleton isLoading={vendorsLoading} variant="layout">
          <section className="flex justify-between gap-8 mb-4 w-full" data-skeleton>
            <div className="flex gap-2 text-body-small self-center" style={{ alignSelf: 'center!important' }}>
              <div className="text-primary-blue-6 font-semibold">
                {vendorsTotal === aggregatedVendorTableData.length
                  ? `${aggregatedVendorTableData.length} vendors from ${properties.length} domains`
                  : `${vendorsTotal} / ${aggregatedVendorTableData.length} vendors from ${properties.length} domains
          `}
              </div>
              <DidomiTooltip contentElementId="tooltipPropertyId" variant="helper" placement="top">
                <DidomiIcon className="ml-xxs" name="helper-text" />
              </DidomiTooltip>
              <div style={{ display: 'none' }} id="tooltipPropertyId">
                You selected:
                {properties.map(prop => {
                  return <p key={prop.name}>- {prop.name}</p>;
                })}
              </div>
            </div>
            <DidomiFiltersBar
              filters={filter || {}}
              data-skeleton
              data-testid="filter-bar-property"
              id="didomi-filters-without-default-value-property"
              defaultValue=""
              showSearch={true}
              onSearchTextChange={handleSearchChange}
              placeholderTextFilter={'Search by name'}
              onFilterValueChange={handleFilterChange}
              onClearAllFilters={handleFiltersReset}
              collapsibleFilters={false}
            ></DidomiFiltersBar>
          </section>
          <DidomiTable
            sortBy={sortConfig.field}
            sortDirection={sortConfig.dir}
            onSortChange={changeSorting}
            sortable={true}
            data-skeleton
            minWidth={300}
            style={{ width: 'calc(100% - 2px)', margin: '16px auto' }}
          >
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                {vendorTableColHeaderName.map((obj, idx) => (
                  <DidomiTableTh key={idx} sortId={obj.sortId} className="text-uppercase">
                    {obj.name.toUpperCase()}
                  </DidomiTableTh>
                ))}
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody>
              <SvlVendorTableRowOld isLoading={false} vendors={filteredVendors} />
            </DidomiTableBody>
          </DidomiTable>
          <DidomiPaginator
            data-skeleton
            data-testid="vendors-paginator"
            className="flex justify-end mt-s"
            page={currPage}
            itemCount={vendorsTotal}
            size={limit}
            onPageSizeChange={setLimit}
            onPageChange={setCurrPage}
          />
        </DidomiSkeleton>
      )}
    </div>
  );
};

export { SvlVendorsListOld };
