import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut, usePaginationQueryParams } from '@hooks';
import { UniqueAggregatedVendor } from '@interfaces';
import { PaginatedResponse, SortConfig } from '@types';

const DEFAULT_LIMIT = 5000;
const SEARCH_DEBOUNCE_TIME = 500;

type UseUniqueAggregatedVendorsOptions = UseQueryOptions<PaginatedResponse<UniqueAggregatedVendor>, AxiosError> & {
  ids?: string[];
  property_id?: string[];
  excluded_property_id?: string[];
  limit?: number;
  skip?: number;
  sort?: SortConfig<keyof UniqueAggregatedVendor>;
};

export const useUniqueAggregatedVendors = ({ ids, property_id, excluded_property_id, ...options }: UseUniqueAggregatedVendorsOptions) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const { limit, page, skip, search, setLimit, setPage, setSearch } = usePaginationQueryParams({
    debounceSearch: true,
    debounceTime: SEARCH_DEBOUNCE_TIME,
  });

  const fetchUniqueVendors = async (): Promise<PaginatedResponse<UniqueAggregatedVendor>> => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<UniqueAggregatedVendor>>(`${CONFIG.environmentConfig.apiBaseUrl}reports/compliances/unique-vendors`, {
      params: {
        organization_id: organizationId,
        report_id: ids || [],
        property_id: property_id || [],
        excluded_property_id: excluded_property_id || [],
        $limit: options.limit || DEFAULT_LIMIT,
        $skip: skip || 0,
        ...(options.sort && {
          [`$sort[${options.sort.field}]`]: options.sort.dir === 'asc' ? 1 : -1,
        }),
      },
    });

    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<UniqueAggregatedVendor>, AxiosError>(
      ['unique-aggregated-vendors', organizationId, ids || [], property_id || [], excluded_property_id || [], limit, skip, options.sort, search],
      fetchUniqueVendors,
      {
        ...options,
      },
    ),
    paginator: {
      page,
      limit,
      search,
      setPage,
      setLimit,
      setSearch,
    },
  };
};
