import { VendorType } from '@enums';

export const defineVendorType = vendor => {
  /**
   * Vendor type calculation description
   * | FIELD           | namespaces         | organization_id | note                                                 | ID    | deprecated |
   * |-----------------|--------------------|-----------------|------------------------------------------------------|-------|------------|
   * | BUILT IN        | didomi | null      | null            | !/Replica of built-in Didomi vendor\s(.*)\s\((.*)\)/ | !null | FALSE      |
   * | BUILT IN REPLICA| null | custom      | !null           | /Replica of built-in Didomi vendor\s(.*)\s\((.*)\)/  | !null | FALSE      |
   * | GATP            | google             | null            | null                                                 | !null | FALSE      |
   * | IAB             | iab2               | null            | null                                                 | !null | FALSE      |
   * | CUSTOM          | null | custom      | !null           | null                                                 | !null | FALSE      |
   * | UNMATCHED       |                    |                 |                                                      | null  | FALSE      |
   */
  if (vendor.functional_namespace === 'custom') return VendorType.CUSTOM;
  if (vendor.functional_namespace === 'built_in') return VendorType.CUSTOM;
  if (vendor.vendor_iabv2_id || vendor.functional_namespace === 'iab2') return VendorType.IAB;
  if (vendor.functional_namespace === 'google') return VendorType.ATP;
  if (!vendor.id) return VendorType.UNMATCHED;
  return VendorType.UNMATCHED;
};
