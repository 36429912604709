import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import { useActiveOrganization, useReleaseFlag } from '@didomi/utility-react';
import { MainLayout, SvlMatchingVendorLayout } from '@layouts';
import { SvlSelectionPage, SvlSelectionPageOld, SvlVendorMatchingPage } from '@pages';
import { MainLayoutOld } from 'layouts/MainLayout/MainLayoutOld';

const App = () => {
  const { organizationId } = useActiveOrganization();
  const [isReportsV2Enabled, isLoadingReportsV2Enabled] = useReleaseFlag('reports_v2');

  return (
    <BrowserRouter basename={`${organizationId}/smart-vendor-list`}>
      <Routes>
        <Route
          path="/:templateId"
          element={
            <div className="p-l">
              {isReportsV2Enabled && !isLoadingReportsV2Enabled ? (
                <MainLayout>
                  <SvlSelectionPage />
                </MainLayout>
              ) : (
                <MainLayoutOld>
                  <SvlSelectionPageOld />
                </MainLayoutOld>
              )}
            </div>
          }
        ></Route>
        <Route
          path="/assign-domain/:templateId"
          element={
            <div className="p-l">
              <SvlMatchingVendorLayout>
                <SvlVendorMatchingPage />
              </SvlMatchingVendorLayout>
            </div>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};

export { App };
