import { useSearchParams, useParams } from 'react-router-dom';
import React, { useState, cloneElement, useEffect, useMemo, useCallback } from 'react';
import {
  DidomiSecondaryHeader,
  DidomiBackButtonLink,
  DidomiModal,
  DidomiIcon,
  DidomiModalHeader,
  DidomiHintbox,
  DidomiBottomBar,
  DidomiButton,
  DidomiSkeleton,
  DidomiSnackbar,
} from '@didomi/ui-atoms-react';
import { useSPARouter } from '@didomi/utility-react';
import { useQueryClient } from 'react-query';
import {
  useProperty,
  usePropertyReportVendors,
  useConsentNoticeTemplateConfig,
  useConsentNoticeTemplate,
  useDetailTabActiveStatus,
  useEditConsentNoticeTemplateConfig,
  useVendors,
} from '@hooks';
import {
  lastExploitableReport,
  prepareTemplateConfigOld,
  defineVendorType,
  mapAndSetExploitableReportsIds,
  mapVendorsBaseFields,
  getModalTextDependingOnSaveActions,
} from '@utils';

interface IMainLayoutProps {
  children: any;
  loading?: boolean;
}
/**
 * Main Layout
 * Default layout with header
 */
export const MainLayoutOld = ({ children }: IMainLayoutProps): JSX.Element => {
  const { navigateTo } = useSPARouter();
  const { activeStep, setActiveStep } = useDetailTabActiveStatus();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [addVendorsConfig, setAddVendorsConfigs] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const { templateId } = useParams();
  const { isLoading: loadingTemplateConfig, data: templateConfig } = useConsentNoticeTemplateConfig(templateId);
  const { data: template, isLoading: loadingTemplate } = useConsentNoticeTemplate(templateId);
  const [reportIds, setReportIds] = useState(null);
  const { data: vendorsFromReportIds, isLoading: isLoadingVendors } = usePropertyReportVendors({ ids: reportIds });
  const { mutateAsync: updateTemplateConfig, isLoading: savingTemplateConfig } = useEditConsentNoticeTemplateConfig();
  const { data: allAvailableVendors = [] } = useVendors();
  const [watchingSavingTemplateLoading, setWatchingSavingTemplateLoading] = useState(false);
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false);
  const [isOpenSaveModaMerge, setIsOpenSaveModalmerge] = useState(false);
  const [updatedConfig, setUpdateConfig] = useState(null);
  useEffect(() => {
    mapAndSetExploitableReportsIds({ properties: selectedProperties, lastExploitableReport, activeStep, setReportIds });
  }, [selectedProperties, activeStep]);

  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      if (!window.location.pathname.includes('/smart-vendor-list')) {
        queryClient.invalidateQueries({ queryKey: ['consent-notice-template-config', templateId] });
      }
      // not testable
      /* istanbul ignore next */
      if (searchParams.get('refresh')) {
        queryClient.invalidateQueries({ queryKey: ['vendors'] });
        searchParams.delete('refresh');
      }
    };
  }, [queryClient, templateId, searchParams]);

  useEffect(() => {
    setIsSnackBarShown(true);
  }, [activeStep]);

  const addVendorTypeFor = vendors => {
    return mapVendorsBaseFields({ vendorsFromApi: vendors, defineVendorType: defineVendorType });
  };

  const isEmptyIabVendors = useMemo(() => {
    if (vendorsFromReportIds) {
      return !vendorsFromReportIds.find(elm => elm.vendor_iabv2_id !== null);
    }
    return true;
  }, [vendorsFromReportIds]);

  const handleBackButton = useCallback(
    refresh => {
      const snowSnackbar = refresh ? '?show-selected-vendors-snackbar=true' : '';
      navigateTo('/consent-notices/vendors-list/' + templateId + snowSnackbar);
      setWatchingSavingTemplateLoading(false);
    },
    [navigateTo, templateId],
  );

  useEffect(() => {
    // not testable
    /* istanbul ignore next */
    if (searchParams?.get('fromPath')) {
      searchParams.delete('fromPath');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const { data: { data: listProperties } = {}, isLoading: isLoadingProperties, error } = useProperty({ withReports: true });

  // not testable
  /* istanbul ignore next */
  const changeStep = (value: number) => {
    if (!selectedProperties.length) return setIsOpen(true);
    searchParams.delete('search');
    searchParams.delete('page');
    setSearchParams(searchParams);
    setActiveStep(value);
  };

  const handleAddVendors = () => {
    updateTemplateConfig(updatedConfig);
    setWatchingSavingTemplateLoading(true);
  };

  useEffect(() => {
    if (!watchingSavingTemplateLoading) return;
    if (savingTemplateConfig) return;
    if (!savingTemplateConfig && watchingSavingTemplateLoading) {
      setWatchingSavingTemplateLoading(false);
      handleBackButton(true);
    }
  }, [watchingSavingTemplateLoading, savingTemplateConfig, setWatchingSavingTemplateLoading, handleBackButton]);

  useEffect(() => {
    if (isOpenSaveModal === true) {
      setUpdateConfig(prepareTemplateConfigOld({ addVendorsConfig, vendors: vendorsFromReportIds, templateConfig, partnerList: allAvailableVendors }));
    }
  }, [isOpenSaveModal, setUpdateConfig, addVendorsConfig, vendorsFromReportIds, templateConfig, allAvailableVendors]);

  const handleOpenSaveModal = () => {
    setUpdateConfig(prepareTemplateConfigOld({ addVendorsConfig, vendors: vendorsFromReportIds, templateConfig, partnerList: allAvailableVendors }));
    if (addVendorsConfig.addMethod === 'merge') {
      return setIsOpenSaveModalmerge(true);
    } else {
      return setIsOpenSaveModal(true);
    }
  };

  const managedVendors = vendorsFromReportIds?.filter(vendor => {
    const vendorType = defineVendorType(vendor);
    return vendorType !== 'unmatched' && addVendorsConfig?.addedVendors?.includes(vendorType);
  });
  const validationModalText = useMemo(() => {
    return getModalTextDependingOnSaveActions({ addVendorsConfig, updatedConfig, managedVendors });
  }, [addVendorsConfig, updatedConfig, managedVendors]);

  const [isSnackBarShown, setIsSnackBarShown] = useState(true);
  const handleCloseSnackBar = () => {
    setIsSnackBarShown(false);
  };
  const isLoadingActions = watchingSavingTemplateLoading || savingTemplateConfig;
  return (
    <section className=" h-full box-border !flex flex-col relative">
      {isSnackBarShown && !isLoadingProperties && !isLoadingVendors && activeStep === 1 && (
        <DidomiSnackbar
          style={{ position: 'fixed', bottom: '60px', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '6' }}
          action-name="Close"
          onActionClick={handleCloseSnackBar}
          variant="error"
          text={`${addVendorTypeFor(vendorsFromReportIds).filter(elm => elm.filterType === 'unmatched')?.length} unmatched vendors. Please match these vendors before continuing.`}
        ></DidomiSnackbar>
      )}
      <DidomiModal permanent={true} isOpen={isOpenSaveModaMerge}>
        <DidomiModalHeader modalSubTitle={validationModalText.title} variant="warning"></DidomiModalHeader>
        <p style={{ fontSize: '14px', marginTop: '14PX' }} className="text-primary-blue-6">
          These vendors <strong>{validationModalText.descMethod}</strong> to the current selection. <br />
          {validationModalText.desc}
        </p>
        <div className="flex mt-m">
          <DidomiButton
            style={{ marginRight: '12px' }}
            variant="secondary"
            onClick={() => {
              setIsOpenSaveModalmerge(false);
            }}
          >
            Cancel
          </DidomiButton>
          <DidomiButton onClick={() => handleAddVendors()}>Merge & Save</DidomiButton>
        </div>
      </DidomiModal>
      <DidomiModal permanent={true} isOpen={isOpenSaveModal}>
        <DidomiModalHeader modalSubTitle={validationModalText.title} variant="warning"></DidomiModalHeader>
        <p style={{ fontSize: '14px', marginTop: '14PX' }} className="text-primary-blue-6">
          These vendors <strong>{validationModalText.descMethod}</strong> the current selection <br />
          {validationModalText.desc}
        </p>
        <div className="flex mt-m">
          <DidomiButton
            style={{ marginRight: '12px' }}
            variant="secondary"
            onClick={() => {
              setIsOpenSaveModal(false);
            }}
          >
            Cancel
          </DidomiButton>
          <DidomiButton onClick={() => handleAddVendors()}>Overwrite & Save</DidomiButton>
        </div>
      </DidomiModal>
      <DidomiModal permanent={true} isOpen={isOpen}>
        <DidomiModalHeader
          illustrationName="warning-modal"
          // modalTitle={'Select domains from the list'}
          modalSubTitle={'You need to select some domains from the list first.'}
          modalDescription={'If the domain you need is not in the list, go to the Compliance Report section in order to add it.'}
          variant="warning"
        ></DidomiModalHeader>
        <div className="flex mt-s">
          <DidomiButton
            className="mr-xs"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Ok
          </DidomiButton>
        </div>
      </DidomiModal>
      <DidomiSkeleton variant="layout" isLoading={isLoadingProperties || loadingTemplate || loadingTemplateConfig}>
        <div>
          <DidomiSecondaryHeader title-text="CMP Vendors sync" actions-in-center>
            <div slot="back-button">
              <DidomiBackButtonLink data-testid="back-btn" className="cursor-pointer" onClick={() => handleBackButton(false)}></DidomiBackButtonLink>
            </div>
            <div slot="description">{template?.name || ''}</div>
            <div slot="actions">
              {activeStep !== 0 && (
                <DidomiButton disabled={isEmptyIabVendors || !!error || isLoadingActions} onClick={() => handleOpenSaveModal()}>
                  {'Add these vendors'}
                </DidomiButton>
              )}
              {activeStep === 0 && (
                <DidomiButton className="flex items-center" onClick={() => changeStep(1)}>
                  Next
                  <DidomiIcon name="right-on" className="ml-xxs"></DidomiIcon>
                </DidomiButton>
              )}
            </div>
          </DidomiSecondaryHeader>
          <DidomiHintbox title-text="Choose one or more domains (step 1). Then choose the vendors to be added to your vendor list (step 2)." className="mt-s">
            <div>
              {
                'The compliance monitoring checks for all active vendors. If one of your domains is missing from the list below, please ensure it is added in your compliance monitoring.'
              }
            </div>
          </DidomiHintbox>
        </div>
        {cloneElement(children, { activeStep, setActiveStep, selectedProperties, setSelectedProperties, toogleValidationModal: setIsOpen, setAddVendorsConfigs })}

        {(listProperties && listProperties.length === 0) || !!error ? (
          ''
        ) : (
          <>
            <DidomiBottomBar className="-mx-l -mb-m flex-shrink-0 h-[55px]" isOpen={true} variant="light" closable={false} style={{ opacity: isLoadingProperties ? '0' : '1' }}>
              <div slot="actions" className="flex items-center gap-4 mt-xs">
                {activeStep !== 0 && (
                  <DidomiButton disabled={isEmptyIabVendors || !!error || isLoadingActions} onClick={() => handleOpenSaveModal()}>
                    {'Add these vendors'}
                  </DidomiButton>
                )}
                {activeStep === 0 && (
                  <DidomiButton data-testId="step1" className="flex items-center" onClick={() => changeStep(1)}>
                    Next
                    <DidomiIcon name="right-on" className="ml-xxs"></DidomiIcon>
                  </DidomiButton>
                )}
              </div>
            </DidomiBottomBar>
          </>
        )}
      </DidomiSkeleton>
    </section>
  );
};
