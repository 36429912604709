export enum VendorNamespace {
  CUSTOM = 'custom',
  DIDOMI = 'didomi',
  IAB2 = 'iab2',
  GOOGLE = 'google',
  BUILT_IN = 'built_in',
  IAB1 = 'iab',
  // Not stored in db. Used to show manually matched vendors
  MANUAL = 'manual',
}
