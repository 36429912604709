import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiTableRow, DidomiTableTd, DidomiButton, DidomiTooltip, DidomiIcon } from '@didomi/ui-atoms-react';
import { useVendorsContext } from '@contexts';
import { VendorNamespace } from '@enums';
import { UniqueAggregatedVendor } from '@interfaces';

interface SvlVendorTableRowProps {
  vendors: UniqueAggregatedVendor[];
  isLoading?: boolean;
}

const SvlVendorTableRow = ({ vendors = [] }: SvlVendorTableRowProps) => {
  const navigate = useNavigate();
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/smart-vendor-list');
  const { templateId } = useParams();
  const { hasHost } = useVendorsContext();

  const isVendorUnmatched = (vendor: UniqueAggregatedVendor) => {
    return !vendor.partner_id && !hasHost(vendor.id);
  };

  const isVendorManual = (vendor: UniqueAggregatedVendor) => {
    return !vendor.partner_id && hasHost(vendor.id);
  };

  const redirectToMatching = (vendor: UniqueAggregatedVendor) => {
    navigate(`/assign-domain/${templateId}/?vendorKey=${vendor.id}`);
  };

  return (
    <>
      {vendors.map((vendor, idx) => (
        <DidomiTableRow key={idx} data-skeleton>
          <DidomiTableTd className={!vendor.partner_id ? 'text-neutral-gray-5' : ''}>
            <td>
              <strong>{vendor.partner_name || vendor.id}</strong>
            </td>
          </DidomiTableTd>
          <DidomiTableTd>
            {vendor.functional_namespace === VendorNamespace.CUSTOM ? (
              <td className="text-primary-blue-6">Custom</td>
            ) : vendor.functional_namespace === VendorNamespace.GOOGLE ? (
              <td className="text-primary-blue-6">
                <img alt="atp logo" src={`${ASSETS_URL}/assets/svg/atp-logo.svg`} />
              </td>
            ) : vendor.functional_namespace === VendorNamespace.IAB2 ? (
              <td>
                <img alt="iab logo" src={`${ASSETS_URL}/assets/svg/iab-logo.svg`} />
              </td>
            ) : vendor.functional_namespace === VendorNamespace.BUILT_IN ? (
              <td className="text-primary-blue-6">Built-in</td>
            ) : isVendorUnmatched(vendor) ? (
              <td className="text-primary-pink-5">Unmatched</td>
            ) : isVendorManual(vendor) ? (
              <td>
                <div className="flex items-center gap-2">
                  <div className="text-primary-blue-6">Manually Matched</div>
                  <DidomiTooltip
                    content="The host has been successfully matched. Changes will be reflected in this column 24h following the next report generation."
                    variant="helper"
                    placement="top"
                  >
                    <DidomiIcon className="ml-xxs" name="helper-text" />
                  </DidomiTooltip>
                </div>
              </td>
            ) : (
              <td></td>
            )}
          </DidomiTableTd>
          <DidomiTableTd>
            <td>
              {isVendorUnmatched(vendor) && (
                <DidomiButton size="small" variant="secondary" onClick={() => redirectToMatching(vendor)}>
                  Find a match
                </DidomiButton>
              )}
            </td>
          </DidomiTableTd>
        </DidomiTableRow>
      ))}
    </>
  );
};

export { SvlVendorTableRow };
