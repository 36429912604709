import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';

import {
  DidomiFiltersBar,
  DidomiIcon,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableTh,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';
import { useQueryClient } from 'react-query';
import { useLocalStorage } from 'usehooks-ts';
import { SvlEmptyData, SvlVendorTableRow } from '@components';
import { LOCAL_STORAGE_KEYS } from '@constants';
import { useVendorsContext } from '@contexts';
import { VendorNamespace } from '@enums';
import { useTableState, useUniqueAggregatedVendors, usePaginatedFilteredItems } from '@hooks';
import { UniqueAggregatedVendor } from '@interfaces';

const DEFAULT_PAGE_SIZE = 20;
const SEARCH_FIELDS = [{ field: 'id' }, { field: 'partner_name' }];

const vendorTableColHeaderName = [
  { name: 'Vendor name', sortId: 'id' },
  { name: 'Vendor type', sortId: null },
  { name: ' ', sortId: null },
];

const filter = {
  functional_namespace: {
    placeholder: 'Vendor type',
    value: null,
    multi: true,
    options: [
      { value: 'unmatched', label: 'Unmatched' },
      { value: VendorNamespace.CUSTOM, label: 'Custom' },
      { value: VendorNamespace.IAB2, label: 'IAB' },
      { value: VendorNamespace.GOOGLE, label: 'Google ATP' },
      { value: VendorNamespace.MANUAL, label: 'Manually Matched' },
    ],
  },
};

type VendorsSortableField = 'id';

interface SvlVendorsListProps {
  activeStep: Number;
  setActiveStep?: Function;
}

const SvlVendorsList = ({ activeStep, setActiveStep }: SvlVendorsListProps) => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const doesRefresh = searchParams.get('refresh');
  const { organizationId } = useActiveOrganization();
  const [storedSelectedProperties] = useLocalStorage<any>(LOCAL_STORAGE_KEYS.SVL_SELECTED_PROPERTIES + organizationId, {});
  const [selectedVendorType, setSelectedVendorType] = useState<string[]>([]);
  const propertyIds = storedSelectedProperties?.data?.map(prop => prop.property_id) || [];
  const { hasHost } = useVendorsContext();

  const filterByVendorType = useMemo(() => {
    return (vendors: UniqueAggregatedVendor[], value: (VendorNamespace | 'unmatched')[]) => {
      if (!value.length) {
        return vendors;
      }

      return vendors.filter(vendor => {
        if (value.includes('unmatched')) {
          return !vendor.partner_id && !hasHost(vendor.id);
        }

        if (value.includes(VendorNamespace.MANUAL)) {
          return !vendor.partner_id && hasHost(vendor.id);
        }

        return value.includes(vendor.functional_namespace);
      });
    };
  }, [hasHost]);

  const {
    data: vendorsResponse,
    isLoading: vendorsLoading,
    error,
  } = useUniqueAggregatedVendors({
    ...(storedSelectedProperties?.allItemsSelected ? { excluded_property_id: storedSelectedProperties?.unselectedPropertyIds } : { property_id: propertyIds }),
    enabled: activeStep === 1,
  });

  const {
    limit,
    page: currPage,
    search,
    changeLimit: setLimit,
    changeSearch: setSearch,
    changePage: setCurrPage,
    changeSorting,
    sortConfig,
  } = useTableState<VendorsSortableField>({
    defaultLimit: DEFAULT_PAGE_SIZE,
    defaultFilters: {},
  });

  const filters = useMemo(() => {
    return [{ filter: filterByVendorType, value: selectedVendorType }];
  }, [selectedVendorType, filterByVendorType]);

  const { data: filteredVendors, total: vendorsTotal } = usePaginatedFilteredItems<UniqueAggregatedVendor, VendorsSortableField>(vendorsResponse?.data || [], {
    search,
    searchFields: SEARCH_FIELDS,
    limit,
    page: currPage,
    sortConfig,
    filters,
  });

  useEffect(() => {
    if (!!doesRefresh === true) {
      queryClient.invalidateQueries({ queryKey: ['unique-aggregated-vendors'] });
      searchParams.delete('refresh');
      setSearchParams(searchParams);
    }
  }, [queryClient, searchParams, doesRefresh, setSearchParams]);

  useEffect(() => {
    if (Math.ceil(vendorsTotal / limit) < currPage) {
      filteredVendors && setCurrPage({ detail: { page: 1 } } as CustomEvent);
    }
  }, [filteredVendors, setCurrPage, vendorsTotal, limit, currPage]);

  const handleFiltersReset = () => {
    setSelectedVendorType([]);
    setSearch('');
  };

  const handleSearchChange = (e: CustomEvent<string>) => setSearch(e.detail);

  const handleFilterChange = (newFilterChange: DidomiFiltersBarCustomEvent<{ key: string; newValue: string }>) => {
    const value = newFilterChange.detail.newValue;
    const key = newFilterChange.detail.key;

    if (key === 'functional_namespace') {
      if (!value.length) {
        return setSelectedVendorType([]);
      }
      setSelectedVendorType(Array.isArray(value) ? value : [value]);
    }
  };

  return (
    <div className="pt-l mb-l">
      {(!vendorsLoading && !!error) || (!vendorsLoading && vendorsResponse?.data?.length === 0) ? (
        <SvlEmptyData error={!!error} noIab={true} setActiveStep={setActiveStep}></SvlEmptyData>
      ) : (
        <DidomiSkeleton isLoading={vendorsLoading} variant="layout">
          <section className="flex justify-between gap-8 mb-4 w-full" data-skeleton>
            <div className="flex gap-2 text-body-small self-center" style={{ alignSelf: 'center!important' }}>
              <div className="text-primary-blue-6 font-semibold">
                {`${filteredVendors?.length || 0} / ${vendorsResponse?.data?.length || 0} vendors from ${propertyIds.length} domains`}
              </div>
              <DidomiTooltip contentElementId="tooltipPropertyId" variant="helper" placement="top">
                <DidomiIcon className="ml-xxs" name="helper-text" />
              </DidomiTooltip>
              <div style={{ display: 'none' }} id="tooltipPropertyId">
                You selected:
                {storedSelectedProperties?.data?.map(prop => {
                  return <p key={prop.property_id}>- {prop.property_name}</p>;
                })}
              </div>
            </div>
            <DidomiFiltersBar
              filters={filter}
              data-skeleton
              data-testid="filter-bar-property"
              id="didomi-filters-without-default-value-property"
              defaultValue=""
              showSearch={true}
              onSearchTextChange={handleSearchChange}
              onFilterValueChange={handleFilterChange}
              placeholderTextFilter={'Search by name'}
              onClearAllFilters={handleFiltersReset}
              collapsibleFilters={false}
            ></DidomiFiltersBar>
          </section>
          <DidomiTable sortable={true} onSortChange={changeSorting} data-skeleton minWidth={300} style={{ width: 'calc(100% - 2px)', margin: '16px auto' }}>
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                {vendorTableColHeaderName.map((obj, idx) => (
                  <DidomiTableTh key={idx} sortId={obj.sortId} className="text-uppercase">
                    {obj.name.toUpperCase()}
                  </DidomiTableTh>
                ))}
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody>
              <SvlVendorTableRow isLoading={false} vendors={filteredVendors || []} />
            </DidomiTableBody>
          </DidomiTable>
          <DidomiPaginator
            data-skeleton
            data-testid="vendors-paginator"
            className="flex justify-end mt-s"
            page={currPage}
            itemCount={vendorsTotal}
            size={limit}
            onPageSizeChange={setLimit}
            onPageChange={setCurrPage}
          />
        </DidomiSkeleton>
      )}
    </div>
  );
};

export { SvlVendorsList };
