import { useParams } from 'react-router-dom';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import {
  DidomiFiltersBar,
  DidomiIcon,
  DidomiPaginator,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableTh,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';
import { useLocalStorage } from 'usehooks-ts';
import { SvlEmptyTable, SvlPropertyTableRowOld } from '@components';

import { usePaginatedFilteredItems, useTableState } from '@hooks';
import { RootProperty, PropertySortableField } from '@types';
import {
  lastExploitableReport,
  lastReportDate,
  isDateOlderThanOneDay,
  handleRowSelectionAndSelectAllOld,
  handlePropertySelectionBasedOnStorage,
  handlePropertySelectionBasedOnStorageOld,
} from '@utils';

const SEARCH_FIELDS = [{ field: 'name' }, { field: 'website' }];
const propertyTableColHeader = [
  { name: 'Domain Name', sortId: 'name' },
  { name: 'Country' },
  { name: 'IAB', sortId: 'tcfv2_vendors_count', helpText: 'The vendors part of the IAB Global Vendor List, which is managed by IAB.' },
  { name: 'Google ATP', sortId: 'atp', helpText: 'The vendors part of the Google ATP vendor list, which is managed by Google.' },
  { name: 'Custom', sortId: 'custom', helpText: 'The vendors created and managed by your organisation in the Data Manager module.' },
  {
    name: 'Unmatched',
    sortId: 'unmatched_slds_count',
    helpText: 'The vendors detected on your domains that were not automatically matched with a mapped vendor (IAB, GOOGLE ATP or Custom). They will require a manual match.',
  },
  { name: 'Last report', sortId: 'last_report_date', longCol: true },
  { name: 'Actions' },
];

interface SvlPropertiesListProps {
  properties: RootProperty[];
  setNextEnabled: Function;
  setSelectedProperties: Function;
  hasComplianceReportEditAccess: boolean;
  ref?: any;
}
const SvlPropertiesListOld = ({ properties, setSelectedProperties, hasComplianceReportEditAccess }: SvlPropertiesListProps) => {
  const { organizationId } = useActiveOrganization();
  const { templateId } = useParams();

  const [storedSelectedProperties, setStoredSelectedProperties] = useLocalStorage<any>('svl-selected-properties' + organizationId, {});
  const aggregatedPropertyTableData = useMemo(() => {
    return properties.map(property => {
      const baseField = {
        tcfv2_vendors_count: lastExploitableReport(property)?.tcfv2_vendors_count || '-',
        unmatched_slds_count: lastExploitableReport(property)?.unmatched_slds_count || '',
        vendors_count: lastExploitableReport(property)?.vendors_count || '-',
        gatp_vendors_count: lastExploitableReport(property)?.gatp_vendors_count || '-',
        custom_vendors_count: lastExploitableReport(property)?.custom_vendors_count || '-',
        last_report_date: lastReportDate(property),
      };
      return {
        ...baseField,
        ...property,
      };
    });
  }, [properties]);
  const filters = useMemo(() => {
    return [];
  }, []);
  const [selectedpropertiesRef, setSelectedpropertiesRef] = useState([]);
  const {
    limit,
    page: currPage,
    search,
    sortConfig,
    changeSorting,
    changeLimit: setLimit,
    changeSearch: setSearch,
    changePage: setCurrPage,
  } = useTableState<PropertySortableField>({
    defaultLimit: 5,
    defaultFilters: {},
  });
  const {
    data: filteredAndPagindatedItems,
    total: propertiesPagination,
    totalItems: totalFilteredItems,
  } = usePaginatedFilteredItems<RootProperty, PropertySortableField>(aggregatedPropertyTableData, {
    search,
    sortConfig,
    searchFields: SEARCH_FIELDS,
    limit,
    page: currPage,
    filters: filters,
  });

  const handleSelection = useCallback(
    evt => {
      return handleRowSelectionAndSelectAllOld({ evt, setSelectedProperties, setSelectedpropertiesRef, setStoredSelectedProperties, totalFilteredItems, templateId });
    },
    [setSelectedProperties, setSelectedpropertiesRef, totalFilteredItems, setStoredSelectedProperties, templateId],
  );
  useEffect(() => {
    handlePropertySelectionBasedOnStorageOld({ selectedpropertiesRef, storedSelectedProperties, isDateOlderThanOneDay, setStoredSelectedProperties, handleSelection });
  }, [storedSelectedProperties, setStoredSelectedProperties, selectedpropertiesRef, handleSelection]);

  useEffect(() => {
    if (storedSelectedProperties?.templateId && templateId !== storedSelectedProperties?.templateId) {
      setStoredSelectedProperties({ noticeId: '', data: [], ttl: new Date() });
      setSelectedpropertiesRef([]);
      setSelectedProperties([]);
    }
  }, [templateId, storedSelectedProperties, setStoredSelectedProperties, setSelectedProperties]);

  const handleSearchChange = (e: CustomEvent<string>) => setSearch(e.detail);
  const screenSize = window.innerWidth;
  return (
    <div className="pt-s">
      <section className="flex justify-between gap-8 mb-4">
        <div className="flex gap-2 self-end text-body-small">
          <div className="text-primary-blue-6 font-semibold">
            {propertiesPagination === aggregatedPropertyTableData.length
              ? `${aggregatedPropertyTableData.length} domains`
              : `${propertiesPagination} / ${aggregatedPropertyTableData.length} domains`}
          </div>
          <div className="text-secondary-cobalt-blue-3">{selectedpropertiesRef.length ? selectedpropertiesRef.length + ' selected' : ''}</div>
        </div>
        <DidomiFiltersBar
          data-testid="filter-bar-property"
          id="didomi-filters-without-default-value-property"
          defaultValue=""
          showSearch={true}
          onSearchTextChange={handleSearchChange}
          placeholderTextFilter={'Search'}
          onClearAllFilters={setSearch}
          collapsibleFilters={false}
        ></DidomiFiltersBar>
      </section>
      {filteredAndPagindatedItems?.length ? (
        <div>
          {/* Selection fixed by a key base on the current page because of a bug when rendering mapped childs
              see explanation here https://github.com/bbellmyers/stencil-slots#stop-gap-solution
          */}
          <DidomiTable
            key={`properties-list-${currPage}`}
            onRowSelectionChange={handleSelection}
            onToggleAllRowsSelectedChange={handleSelection}
            selectable
            selectedItems={selectedpropertiesRef}
            sortBy={sortConfig.field}
            sortDirection={sortConfig.dir}
            onSortChange={changeSorting}
            sortable={true}
            minWidth={1480}
            style={{ width: 'calc(100% - 2px)', margin: '16px auto' }}
          >
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                {propertyTableColHeader.map((obj, idx) => (
                  <DidomiTableTh
                    key={idx}
                    className="text-uppercase"
                    sortId={obj?.sortId || ''}
                    style={{ flex: obj.name === 'Domain Name' ? '0 0 200px' : obj.longCol ? '0 0 280px' : screenSize > 1450 ? '1' : '0 0 140px' }}
                  >
                    <div className="flex items-center">
                      {obj.name.toUpperCase()}
                      {obj?.helpText && (
                        <DidomiTooltip content={obj.helpText} variant="helper" placement="top">
                          <DidomiIcon className="ml-xxs" name="helper-text" />
                        </DidomiTooltip>
                      )}
                    </div>
                  </DidomiTableTh>
                ))}
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody>
              <SvlPropertyTableRowOld isLoading={false} properties={filteredAndPagindatedItems} hasComplianceReportEditAccess={hasComplianceReportEditAccess} />
            </DidomiTableBody>
          </DidomiTable>
        </div>
      ) : (
        <SvlEmptyTable />
      )}
      <div className="flex justify-end">
        <DidomiPaginator
          data-testid="vendors-paginator"
          className="self-end"
          page={currPage}
          itemCount={propertiesPagination}
          size={limit}
          onPageSizeChange={setLimit}
          onPageChange={setCurrPage}
        />
      </div>
    </div>
  );
};

export { SvlPropertiesListOld };
