import { addAxiosResponseInterceptors } from '@didomi/helpers/dist/response-interceptors/axios/axios-response-interceptors';
import { useAuthMethods, useAuthToken } from '@didomi/utility-react';
import axios, { AxiosInstance } from 'axios';
import { stringify } from 'qs';

const axiosWithLogoutInstance = axios.create();
let axiosWithLogoutInterceptors: AxiosInstance;

export const useAxiosWithLogOut = ({ skipLogoutOnForbidden } = { skipLogoutOnForbidden: false }) => {
  const token = useAuthToken();

  axiosWithLogoutInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axiosWithLogoutInstance.defaults.paramsSerializer = params => stringify(params);

  const { logout } = useAuthMethods();
  if (axiosWithLogoutInterceptors) {
    return axiosWithLogoutInterceptors;
  }

  axiosWithLogoutInterceptors = addAxiosResponseInterceptors(axiosWithLogoutInstance, { onExpiredToken: logout, onForbidden: skipLogoutOnForbidden ? () => {} : undefined });
  return axiosWithLogoutInterceptors;
};
