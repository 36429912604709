export const handleRowSelectionAndSelectAllOld = ({ evt, setSelectedProperties, setSelectedpropertiesRef, setStoredSelectedProperties, totalFilteredItems, templateId }) => {
  if (evt.type === 'toggleAllRowsSelectedChange') {
    if (evt.detail.selectedItems.length) {
      setStoredSelectedProperties({ templateId: templateId, data: totalFilteredItems, ttl: new Date() });
      setSelectedpropertiesRef(totalFilteredItems);
      setSelectedProperties(totalFilteredItems);
    } else {
      setStoredSelectedProperties({ templateId: templateId, data: [], ttl: new Date() });
      setSelectedpropertiesRef([]);
      setSelectedProperties([]);
    }
  } else {
    setStoredSelectedProperties({ templateId: templateId, data: evt.detail.newSelectedItems, ttl: new Date() });
    setSelectedpropertiesRef(evt.detail.newSelectedItems);
    setSelectedProperties(evt.detail.newSelectedItems);
  }
};

export const handleRowSelectionAndSelectAll = ({ evt, setStoredSelectedProperties, totalFilteredItems, templateId, currentAllItemsSelected, unselectedPropertyIds }) => {
  if (evt.type === 'toggleAllRowsSelectedChange') {
    if (evt.detail.selectedItems.length) {
      setStoredSelectedProperties({
        templateId,
        data: totalFilteredItems,
        allItemsSelected: true,
        ttl: new Date(),
        unselectedPropertyIds: [],
      });
    } else {
      setStoredSelectedProperties({
        templateId,
        data: [],
        allItemsSelected: false,
        ttl: new Date(),
        unselectedPropertyIds: [],
      });
    }
  } else {
    setStoredSelectedProperties({
      templateId,
      data: evt.detail.newSelectedItems,
      ttl: new Date(),
      allItemsSelected: currentAllItemsSelected,
      unselectedPropertyIds,
    });
  }
};

export const handlePropertySelectionBasedOnStorage = ({ storedSelectedProperties, isDateOlderThanOneDay, setStoredSelectedProperties, handleSelection }) => {
  if (storedSelectedProperties?.data?.length) {
    if (isDateOlderThanOneDay(storedSelectedProperties.ttl)) return setStoredSelectedProperties({ noticeId: '', data: [], ttl: new Date() });
    handleSelection({ detail: { newSelectedItems: storedSelectedProperties.data } });
  }
};

export const handlePropertySelectionBasedOnStorageOld = ({
  selectedpropertiesRef,
  storedSelectedProperties,
  isDateOlderThanOneDay,
  setStoredSelectedProperties,
  handleSelection,
}) => {
  if (selectedpropertiesRef.length === 0 && storedSelectedProperties.data && storedSelectedProperties.data.length) {
    if (isDateOlderThanOneDay(storedSelectedProperties.ttl)) return setStoredSelectedProperties({ noticeId: '', data: [], ttl: new Date() });
    handleSelection({ detail: { newSelectedItems: storedSelectedProperties.data } });
  }
};
