import React, { useRef, useEffect } from 'react';
import { DidomiTabs, DidomiTabHeaders, DidomiTabPanels, DidomiTabHeader, DidomiTabPanel, DidomiTabHeading, DidomiLoader } from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';
import { useLocalStorage } from 'usehooks-ts';
import { SvlPropertiesList, SvlVendorCardSelection, SvlVendorsList, SvlEmptyData } from '@components';
import { LOCAL_STORAGE_KEYS } from '@constants';
import { usePropertiesWithVendorCounts, useUniqueAggregatedVendors } from '@hooks';
import { PropertyWithVendorsCount } from '@interfaces';

interface ComponentProps {
  activeStep?: number;
  setNextEnabled?: Function;
  setActiveStep?: Function;
  selectedProperties?: PropertyWithVendorsCount[];
  setSelectedProperties?: Function;
  setAddVendorsConfigs?: Function;
}

const SvlSelectionPage = ({ setAddVendorsConfigs, activeStep, setNextEnabled, setActiveStep }: ComponentProps) => {
  const initialLoadRef = useRef(true);
  const {
    data: { data: listProperties } = {},
    isLoading: isLoadingProperties,
    error,
    paginator: { search },
  } = usePropertiesWithVendorCounts();
  const { organizationId } = useActiveOrganization();
  const [storedSelectedProperties] = useLocalStorage<any>(LOCAL_STORAGE_KEYS.SVL_SELECTED_PROPERTIES + organizationId, {});
  const propertyIds = storedSelectedProperties?.data?.map(property => property.property_id) || [];

  const {
    data: vendorsResponse,
    isLoading: vendorsLoading,
    error: vendorsError,
  } = useUniqueAggregatedVendors({
    ...(storedSelectedProperties?.allItemsSelected ? { excluded_property_id: storedSelectedProperties?.unselectedPropertyIds } : { property_id: propertyIds }),
    enabled: activeStep === 1,
  });

  useEffect(() => {
    if (!isLoadingProperties) {
      initialLoadRef.current = false;
    }
  }, [isLoadingProperties]);

  const isLoading = vendorsLoading || (isLoadingProperties && initialLoadRef.current);

  return (
    <div className="pt-m">
      {isLoading ? (
        <div className="flex flex-col items-center justify-center">
          <DidomiLoader className="mb-xs" />
          <div className="text-body-normal text-primary-blue-5">Loading...</div>
        </div>
      ) : (
        <div>
          {listProperties && listProperties.length === 0 && !search && !isLoadingProperties ? (
            <SvlEmptyData error={!!error || !!vendorsError} code={error || vendorsError} />
          ) : (
            <DidomiTabs active-tab={activeStep}>
              <DidomiTabHeading variant="flow">
                <DidomiTabHeaders>
                  <DidomiTabHeader
                    name="1. Choose from your domains"
                    onHeaderClicked={() => {
                      setActiveStep(0);
                    }}
                  ></DidomiTabHeader>
                  <DidomiTabHeader disabled={activeStep === 0} name="2. Confirm vendor selection"></DidomiTabHeader>
                </DidomiTabHeaders>
              </DidomiTabHeading>
              <DidomiTabPanels>
                <DidomiTabPanel style={{ paddingRight: '3px' }}>
                  <SvlPropertiesList />
                </DidomiTabPanel>
                <DidomiTabPanel style={{ paddingRight: '3px', 'overflow-x': 'visible' }}>
                  <section className="flex justify-betweeen">
                    <div className="w-[60%] px-l">
                      <SvlVendorsList activeStep={activeStep} setActiveStep={setActiveStep} />
                    </div>
                    <div className="w-[40%] pl-l">
                      <SvlVendorCardSelection setAddVendorsConfigs={setAddVendorsConfigs} vendors={vendorsResponse?.data} />
                    </div>
                  </section>
                </DidomiTabPanel>
              </DidomiTabPanels>
            </DidomiTabs>
          )}
        </div>
      )}
    </div>
  );
};

export { SvlSelectionPage };
