import { useSearchParams, useNavigate } from 'react-router-dom';
import React from 'react';
import { DidomiTableRow, DidomiTableTd, DidomiButton, DidomiModal, DidomiLoader, DidomiModalContent, DidomiTooltip } from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';
import { useQueryClient } from 'react-query';

import { useEditVendor, useCreateVendor } from '@hooks';
import { Vendor, VendorRequestBody } from '@types';
import { handleExistingVendorUpdate, handleNewVendorCreation, typeOfVendor, handlePurposesText } from '@utils';
interface Props {
  vendors: Vendor[];
  isLoading?: boolean;
}
const SvlVendorMatchingTableRow = ({ vendors = [] }: Props) => {
  const { mutateAsync: editVendor, isLoading: isEditing } = useEditVendor();
  const { mutateAsync: createVendor, isLoading: isCreating } = useCreateVendor();
  const isLoading = isCreating || isEditing;
  const navigate = useNavigate();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  let [searchParams] = useSearchParams();

  const vendorKey = searchParams.get('vendorKey');

  // unable to click event
  /* istanbul ignore next */
  const generateVendorRequestBody = (vendor: Vendor): VendorRequestBody => {
    let address = {};
    let contact = {};
    const name = typeof vendor.name === 'string' ? vendor.name : '';
    if (typeof vendor.address === 'string') {
      // If address is a string, convert it into the desired object format
      address = { default: vendor.address };
    } else {
      // If address is already an object, use it as is
      address = vendor.address;
    }

    if (typeof vendor.contact === 'string') {
      // If contact is a string, convert it into the desired object format
      contact = { default: vendor.contact };
    } else {
      // If contact is already an object, use it as is
      contact = vendor.contact;
    }

    return {
      address: address,
      contact: contact,
      spi_purposes_id: vendor.spi_purposes_id || [],
      cookie_max_duration: vendor.cookie_max_duration || null,
      cookie_max_duration_unit: vendor.cookie_max_duration_unit || 'months',
      country_id: vendor.country_id || null,
      default_purposes_id: vendor.default_purposes_id || [],
      legitimate_interest_purposes_id: vendor.legitimate_interest_purposes_id || [],
      links: vendor.links,
      hosts: vendor?.hosts?.length ? Array.from(new Set([...vendor.hosts, vendorKey])) : [vendorKey],
      name: name || '',
      organization_id: organizationId,
      protection_id: vendor.protection_id || null,
      category_id: vendor.category_id || 'service-provider',
      taxonomy_id: vendor.taxonomy_id || null,
      uses_non_cookie_access: vendor.uses_non_cookie_access || null,
      last_metadata_partner_event_time: vendor.updated_at,
    };
  };

  const redirectBack = () => {
    queryClient.resetQueries();
    const location = window.location.pathname;
    navigate(`${location.replace(`/${organizationId}/smart-vendor-list/assign-domain`, '')}?activeTab=vendor`);
  };

  // unable to click event but function tested inside
  /* istanbul ignore next */
  const handleClickOnMatchfromId = async (id: string) => {
    const vendor = vendors.find((vendor: Vendor) => vendor.id === id);
    const requestBody = generateVendorRequestBody(vendor);

    if (vendor && vendor.namespaces === null && vendor.organization_id === organizationId) {
      handleExistingVendorUpdate({ requestBody, editVendor, vendor, redirectBack });
    } else {
      handleNewVendorCreation({ requestBody, vendorKey, vendor, createVendor, redirectBack });
    }
  };

  return (
    <>
      <DidomiModal isOpen={isLoading}>
        <DidomiLoader></DidomiLoader>
        <DidomiModalContent className="mt-m">{'Updating vendors...'}</DidomiModalContent>
      </DidomiModal>
      {vendors.map((vendor: Vendor, idx) => {
        return (
          <>
            <DidomiTableRow key={idx} data-skeleton>
              <DidomiTableTd>
                <td>{vendor.name}</td>
              </DidomiTableTd>
              <DidomiTableTd>
                <td>{typeOfVendor(vendor) === 'Custom' ? vendor.id : ''}</td>
              </DidomiTableTd>
              <DidomiTableTd>
                <td>{typeOfVendor(vendor) === 'Custom' ? vendor.sdk_id : ''}</td>
              </DidomiTableTd>
              <DidomiTableTd>
                <td>
                  {typeOfVendor(vendor) === 'Custom' ? (
                    <td className="text-primary-blue-6">Custom</td>
                  ) : typeOfVendor(vendor) === 'Model' ? (
                    <td className="text-primary-pink-5">Model</td>
                  ) : (
                    <td></td>
                  )}
                </td>
              </DidomiTableTd>
              <DidomiTableTd>
                <td>
                  {vendor.default_purposes_id?.length ? handlePurposesText(vendor.default_purposes_id.toString()).title : '-'}

                  <>
                    <DidomiTooltip contentElementId={`purpose_id_${vendor.id}`} variant="helper" placement="top">
                      <span className="text-neutral-gray-6 cursor-pointer">
                        {handlePurposesText(vendor.default_purposes_id.toString()).items.length > 1
                          ? `+ (${handlePurposesText(vendor.default_purposes_id.toString()).items.length - 1})`
                          : ''}
                      </span>
                    </DidomiTooltip>
                    <div style={{ display: 'none' }} id={`purpose_id_${vendor.id}`}>
                      {handlePurposesText(vendor.default_purposes_id.toString()).items.map((item, k) => {
                        return <p key={k}>- {item}</p>;
                      })}
                    </div>
                  </>
                </td>
              </DidomiTableTd>
              <DidomiTableTd>
                <td>
                  {vendor.legitimate_interest_purposes_id?.length ? handlePurposesText(vendor.legitimate_interest_purposes_id?.toString()).title : '-'}
                  <>
                    <DidomiTooltip contentElementId={`legint_id_${vendor.id}`} variant="helper" placement="top">
                      <span className="text-neutral-gray-6 cursor-pointer">
                        {handlePurposesText(vendor.legitimate_interest_purposes_id.toString()).items.length > 1
                          ? `+ (${handlePurposesText(vendor.default_purposes_id.toString()).items.length - 1})`
                          : ''}
                      </span>
                    </DidomiTooltip>
                    <div style={{ display: 'none' }} id={`legint_id_${vendor.id}`}>
                      {handlePurposesText(vendor.legitimate_interest_purposes_id.toString()).items.map((item, k) => {
                        return <p key={k}>- {item}</p>;
                      })}
                    </div>
                  </>
                </td>
              </DidomiTableTd>
              <DidomiTableTd>
                <td>
                  <DidomiButton variant="secondary" size="small" onClick={() => handleClickOnMatchfromId(vendor.id)}>
                    Match to this vendor
                  </DidomiButton>
                </td>
              </DidomiTableTd>
            </DidomiTableRow>
          </>
        );
      })}
    </>
  );
};

export { SvlVendorMatchingTableRow };
