import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut, usePaginationQueryParams } from '@hooks';
import { PropertyWithVendorsCount } from '@interfaces';
import { PaginatedResponse, SortConfig } from '@types';

const DEFAULT_LIMIT = 1000;
const DEFAULT_PAGE_SIZE = 5;
const SEARCH_DEBOUNCE_TIME = 500;

type UsePropertiesWithVendorCountsOptions = UseQueryOptions<PaginatedResponse<PropertyWithVendorsCount>, AxiosError> & {
  property_id?: string[];
  excluded_property_id?: string[];
  limit?: number;
  skip?: number;
  sort?: SortConfig<keyof PropertyWithVendorsCount>;
};

export const usePropertiesWithVendorCounts = ({ property_id, excluded_property_id, ...options }: UsePropertiesWithVendorCountsOptions = {}) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const { limit, page, skip, search, setLimit, setPage, setSearch } = usePaginationQueryParams({
    defaultLimit: DEFAULT_PAGE_SIZE,
    debounceSearch: true,
    debounceTime: SEARCH_DEBOUNCE_TIME,
  });

  const fetchProperties = async (): Promise<PaginatedResponse<PropertyWithVendorsCount>> => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<PropertyWithVendorsCount>>(
      `${CONFIG.environmentConfig.apiBaseUrl}reports/compliances/properties/vendors-count`,
      {
        params: {
          organization_id: organizationId,
          property_id: property_id || [],
          excluded_property_id: excluded_property_id || [],
          $limit: options.limit || limit || DEFAULT_LIMIT,
          $skip: skip || 0,
          ...(search && { $or: [{ property_name: { $ilike: search } }, { website: { $ilike: search } }] }),
          ...(options.sort && {
            [`$sort[${options.sort.field}]`]: options.sort.dir === 'asc' ? 1 : -1,
          }),
        },
      },
    );

    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<PropertyWithVendorsCount>, AxiosError>(
      ['properties-with-vendor-counts', organizationId, property_id || [], excluded_property_id || [], limit, skip, options.sort, search],
      fetchProperties,
      {
        ...options,
      },
    ),
    paginator: {
      page,
      limit,
      search,
      setPage,
      setLimit,
      setSearch,
    },
  };
};
