import { useParams } from 'react-router-dom';
import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import {
  DidomiFiltersBar,
  DidomiIcon,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableTh,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { useActiveOrganization, useSnackbar } from '@didomi/utility-react';
import { useLocalStorage } from 'usehooks-ts';
import { SvlPropertyTableRow, SvlEmptyTable } from '@components';

import { LOCAL_STORAGE_KEYS } from '@constants';
import { usePropertiesWithVendorCounts } from '@hooks';
import { PropertyWithVendorsCount } from '@interfaces';
import { SortConfig } from '@types';
import { isDateOlderThanOneDay, handlePropertySelectionBasedOnStorage, handleRowSelectionAndSelectAll } from '@utils';

const propertyTableColHeader = [
  { name: 'Domain Name', sortId: 'property_name' },
  { name: 'Country' },
  { name: 'IAB', sortId: 'iab2_vendors_count', helpText: 'The vendors part of the IAB Global Vendor List, which is managed by IAB.' },
  { name: 'Google ATP', sortId: 'gatp_vendors_count', helpText: 'The vendors part of the Google ATP vendor list, which is managed by Google.' },
  { name: 'Custom', sortId: 'custom_vendors_count', helpText: 'The vendors created and managed by your organisation in the Data Manager module.' },
  {
    name: 'Unmatched',
    sortId: 'unmatched_vendors_count',
    helpText: 'The vendors detected on your domains that were not automatically matched with a mapped vendor (IAB, GOOGLE ATP or Custom). They will require a manual match.',
  },
  {
    name: 'Last report',
    sortId: 'last_report_date',
    longCol: true,
    helpText: 'Date of the last report available for the CMP vendor sync. Note: It takes 24 hours for reports data to be available for this feature.',
  },
];

const SvlPropertiesList = () => {
  const { organizationId } = useActiveOrganization();
  const { templateId } = useParams();
  const [sort, setSort] = useState<SortConfig<keyof PropertyWithVendorsCount> | null>(null);
  const initialLoadRef = useRef(true);

  const [storedSelectedProperties, setStoredSelectedProperties] = useLocalStorage<any>(LOCAL_STORAGE_KEYS.SVL_SELECTED_PROPERTIES + organizationId, {});
  const { displaySnackbar } = useSnackbar();

  const {
    data: propertiesResponse,
    isLoading: isLoadingProperties,
    error,
    paginator: { page: currPage, limit, search, setPage: setCurrPage, setLimit, setSearch },
  } = usePropertiesWithVendorCounts({
    sort,
    onError: (error: any) => {
      displaySnackbar(error?.message || 'An error occurred while loading properties', {
        variant: 'error',
      });
    },
  });

  const isLoading = isLoadingProperties && initialLoadRef.current;

  const handleSelection = useCallback(
    (evt: CustomEvent) => {
      let updatedUnselectedIds = storedSelectedProperties.unselectedPropertyIds || [];

      if (evt.detail.type === 'unselect') {
        updatedUnselectedIds = Array.from(new Set([...updatedUnselectedIds, evt.detail.selectedItem.property_id]));
        setStoredSelectedProperties({ ...storedSelectedProperties, unselectedPropertyIds: updatedUnselectedIds });
      } else if (evt.detail.type === 'select') {
        updatedUnselectedIds = storedSelectedProperties?.unselectedPropertyIds?.filter(id => id !== evt.detail.selectedItem.property_id);
        setStoredSelectedProperties({ ...storedSelectedProperties, unselectedPropertyIds: updatedUnselectedIds });
      }

      return handleRowSelectionAndSelectAll({
        evt,
        setStoredSelectedProperties,
        totalFilteredItems: propertiesResponse?.data || [],
        templateId,
        currentAllItemsSelected: storedSelectedProperties.allItemsSelected,
        unselectedPropertyIds: updatedUnselectedIds,
      });
    },
    [storedSelectedProperties, setStoredSelectedProperties, propertiesResponse?.data, templateId],
  );

  useEffect(() => {
    handlePropertySelectionBasedOnStorage({
      storedSelectedProperties,
      isDateOlderThanOneDay,
      setStoredSelectedProperties,
      handleSelection,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (storedSelectedProperties?.templateId && templateId !== storedSelectedProperties?.templateId) {
      setStoredSelectedProperties({ noticeId: '', data: [], ttl: new Date() });
    }
  }, [templateId, storedSelectedProperties, setStoredSelectedProperties]);

  useEffect(() => {
    // If allItemsSelected is true, set the selected properties to the properties response data when pages change
    if (storedSelectedProperties.allItemsSelected && propertiesResponse?.data) {
      const filteredProperties = propertiesResponse.data.filter(property => !storedSelectedProperties.unselectedPropertyIds.includes(property.property_id));
      setStoredSelectedProperties(prev => ({
        ...prev,
        data: filteredProperties,
      }));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [propertiesResponse?.data]);

  useEffect(() => {
    // Track the initial load of the properties list
    if (!isLoadingProperties) {
      initialLoadRef.current = false;
    }
  }, [isLoadingProperties]);

  const handleSortChange = (e: CustomEvent<{ sortId: string; direction: 'asc' | 'desc' }>) => {
    setSort({
      field: e.detail.sortId as keyof PropertyWithVendorsCount,
      dir: e.detail.direction,
    });
  };
  const propertiesSelectedCount = useMemo(() => {
    const totalSelectedWithUnselected = (propertiesResponse?.total || 0) - storedSelectedProperties?.unselectedPropertyIds?.length;

    return storedSelectedProperties.allItemsSelected ? totalSelectedWithUnselected : storedSelectedProperties?.data?.length;
  }, [propertiesResponse?.total, storedSelectedProperties.allItemsSelected, storedSelectedProperties?.unselectedPropertyIds?.length, storedSelectedProperties?.data]);

  const screenSize = window.innerWidth;
  return (
    <div className="pt-s">
      <section className="flex justify-between gap-8 mb-4">
        <div className="flex gap-2 self-end text-body-small">
          <div className="text-primary-blue-6 font-semibold">{`${propertiesResponse?.data?.length || 0} / ${propertiesResponse?.total || 0} domains`}</div>
          <div className="text-secondary-cobalt-blue-3">{propertiesSelectedCount ? propertiesSelectedCount + ' selected' : ''}</div>
        </div>
        <DidomiFiltersBar
          data-testid="filter-bar-property"
          id="didomi-filters-without-default-value-property"
          defaultValue={search}
          searchValue={search}
          showSearch={true}
          onSearchTextChange={setSearch}
          placeholderTextFilter={'Search'}
          onClearAllFilters={setSearch}
          collapsibleFilters={false}
        ></DidomiFiltersBar>
      </section>
      {!isLoading && propertiesResponse?.data?.length === 0 ? (
        <SvlEmptyTable />
      ) : (
        <div>
          <DidomiTable
            key={`properties-list-${currPage}`}
            onRowSelectionChange={handleSelection}
            onToggleAllRowsSelectedChange={handleSelection}
            selectable
            selectedItems={storedSelectedProperties?.data || []}
            sortBy={sort?.field}
            sortDirection={sort?.dir}
            onSortChange={handleSortChange}
            sortable={true}
            minWidth={1480}
            style={{ width: 'calc(100% - 2px)', margin: '16px auto' }}
          >
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                {propertyTableColHeader.map((obj, idx) => (
                  <DidomiTableTh
                    key={idx}
                    className="text-uppercase"
                    sortId={obj?.sortId || ''}
                    style={{ flex: obj.name === 'Domain Name' ? '0 0 200px' : obj.longCol ? '0 0 280px' : screenSize > 1450 ? '1' : '0 0 140px' }}
                  >
                    <div className="flex items-center">
                      {obj.name.toUpperCase()}
                      {obj?.helpText && (
                        <DidomiTooltip content={obj.helpText} variant="helper" placement="top">
                          <DidomiIcon className="ml-xxs" name="helper-text" />
                        </DidomiTooltip>
                      )}
                    </div>
                  </DidomiTableTh>
                ))}
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody>
              <SvlPropertyTableRow properties={propertiesResponse?.data || []} />
            </DidomiTableBody>
          </DidomiTable>
        </div>
      )}

      {isLoadingProperties &&
        Array.from(Array(limit), (_, i) => {
          return <DidomiSkeleton key={i} isLoading={true} className="h-[85px] w-full" data-testid={`properties-list-skeleton-${i + 1}`} />;
        })}

      <div className="flex justify-end">
        <DidomiPaginator
          data-testid="vendors-paginator"
          className="self-end"
          page={currPage}
          itemCount={propertiesResponse?.total || 0}
          size={limit}
          onPageSizeChange={setLimit}
          onPageChange={setCurrPage}
        />
      </div>
    </div>
  );
};

export { SvlPropertiesList };
