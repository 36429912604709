import React from 'react';
import { DidomiLanguageFlag, DidomiTableRow, DidomiTableTd } from '@didomi/ui-atoms-react';
import { PropertyWithVendorsCount } from '@interfaces';
import { COUNTRY_CELL_DATA_PER_COUNTRY_CODE, formatDate } from '@utils';

interface SvlPropertyTableRowProps {
  properties: PropertyWithVendorsCount[];
}

const SvlPropertyTableRow = ({ properties = [] }: SvlPropertyTableRowProps) => {
  const screenSize = window.innerWidth;
  return (
    <div>
      {properties.map(property => {
        const lastAvailableReport = property.last_report_date ? `Last available report - ${formatDate(new Date(property.last_report_date))}` : 'No report generated';

        return (
          <DidomiTableRow key={property.property_id} data-skeleton selectionValue={property}>
            <DidomiTableTd style={{ flex: '0 0 200px' }}>
              <td>
                <strong>{property.property_name}</strong>
              </td>

              <td>{property.website || '(no website)'}</td>
            </DidomiTableTd>
            <DidomiTableTd style={{ flex: screenSize > 1450 ? '1' : '0 0 140px' }}>
              <td className="flex items-center gap-xxs">
                <DidomiLanguageFlag language={COUNTRY_CELL_DATA_PER_COUNTRY_CODE[property.property_country]?.icon} style={{ '--icon-size': '15px' }} />
                {COUNTRY_CELL_DATA_PER_COUNTRY_CODE[property.property_country]?.label}
              </td>
            </DidomiTableTd>
            <DidomiTableTd style={{ flex: screenSize > 1450 ? '1' : '0 0 140px' }}>
              <td>{property.iab2_vendors_count}</td>
            </DidomiTableTd>
            <DidomiTableTd style={{ flex: screenSize > 1450 ? '1' : '0 0 140px' }}>
              <td>{property.gatp_vendors_count || '-'}</td>
            </DidomiTableTd>
            <DidomiTableTd style={{ flex: screenSize > 1450 ? '1' : '0 0 140px' }}>
              <td>{property.custom_vendors_count || '-'}</td>
            </DidomiTableTd>
            <DidomiTableTd style={{ flex: screenSize > 1450 ? '1' : '0 0 140px' }}>
              <td>{property.unmatched_vendors_count || '-'}</td>
            </DidomiTableTd>
            <DidomiTableTd style={{ flex: '0 0 280px' }}>
              <td>
                <div slot="header-actions" className="flex flex-row items-center" data-cy="card-header">
                  <p className="text-neutral-gray-0 mr-xs field-label self-center	">{lastAvailableReport}</p>
                </div>
              </td>
            </DidomiTableTd>
          </DidomiTableRow>
        );
      })}
    </div>
  );
};

export { SvlPropertyTableRow };
