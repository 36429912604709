import { useSearchParams, useParams } from 'react-router-dom';
import React, { useState, cloneElement, useEffect, useMemo, useCallback } from 'react';
import {
  DidomiSecondaryHeader,
  DidomiBackButtonLink,
  DidomiModal,
  DidomiIcon,
  DidomiModalHeader,
  DidomiHintbox,
  DidomiBottomBar,
  DidomiButton,
  DidomiSkeleton,
  DidomiSnackbar,
} from '@didomi/ui-atoms-react';
import { useActiveOrganization, useSPARouter } from '@didomi/utility-react';
import { useQueryClient } from 'react-query';
import { useLocalStorage } from 'usehooks-ts';
import { LOCAL_STORAGE_KEYS } from '@constants';
import { VendorsProvider } from '@contexts';
import { VendorNamespace } from '@enums';
import {
  useAggregatedVendors,
  useConsentNoticeTemplateConfig,
  useConsentNoticeTemplate,
  useDetailTabActiveStatus,
  useEditConsentNoticeTemplateConfig,
  useVendors,
  useUniqueAggregatedVendors,
} from '@hooks';
import { UniqueAggregatedVendor } from '@interfaces';
import { getModalTextDependingOnSaveActions, prepareTemplateConfig } from '@utils';
import { usePropertiesWithVendorCounts } from '../../hooks/properties-with-vendor-counts/usePropertiesWithVendorCounts.hook';

interface IMainLayoutProps {
  children: any;
  loading?: boolean;
}
/**
 * Main Layout
 * Default layout with header
 */
export const MainLayout = ({ children }: IMainLayoutProps): JSX.Element => {
  const { navigateTo } = useSPARouter();
  const { activeStep, setActiveStep } = useDetailTabActiveStatus();
  const [isOpen, setIsOpen] = useState(false);
  const [addVendorsConfig, setAddVendorsConfigs] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const { templateId } = useParams();
  const { isLoading: loadingTemplateConfig, data: templateConfig } = useConsentNoticeTemplateConfig(templateId);
  const { data: template, isLoading: loadingTemplate } = useConsentNoticeTemplate(templateId);
  const { organizationId } = useActiveOrganization();
  const [storedSelectedProperties] = useLocalStorage<any>(LOCAL_STORAGE_KEYS.SVL_SELECTED_PROPERTIES + organizationId, {});
  const propertyIds = storedSelectedProperties?.data?.map(property => property.property_id) || [];

  const { data: { data: vendorsFromPropertyIds } = { data: [] as UniqueAggregatedVendor[] }, isLoading: isLoadingVendors } = useUniqueAggregatedVendors({
    ...(storedSelectedProperties?.allItemsSelected ? { excluded_property_id: storedSelectedProperties?.unselectedPropertyIds } : { property_id: propertyIds }),
    enabled: !!propertyIds?.length && activeStep !== 0,
  });

  const { mutateAsync: updateTemplateConfig, isLoading: savingTemplateConfig } = useEditConsentNoticeTemplateConfig();
  const { data: allAvailableVendors = [] } = useVendors();
  const [watchingSavingTemplateLoading, setWatchingSavingTemplateLoading] = useState(false);
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false);
  const [isOpenSaveModaMerge, setIsOpenSaveModalmerge] = useState(false);
  const [updatedConfig, setUpdateConfig] = useState(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      if (!window.location.pathname.includes('/smart-vendor-list')) {
        queryClient.invalidateQueries({ queryKey: ['consent-notice-template-config', templateId] });
      }
      // not testable
      /* istanbul ignore next */
      if (searchParams.get('refresh')) {
        queryClient.invalidateQueries({ queryKey: ['vendors'] });
        searchParams.delete('refresh');
      }
    };
  }, [queryClient, templateId, searchParams]);

  useEffect(() => {
    setIsSnackBarShown(true);
  }, [activeStep]);

  const isEmptyIabVendors = useMemo(() => {
    if (vendorsFromPropertyIds) {
      return !vendorsFromPropertyIds.find(elm => elm.functional_namespace === VendorNamespace.IAB2);
    }
    return true;
  }, [vendorsFromPropertyIds]);

  const handleBackButton = useCallback(
    refresh => {
      const snowSnackbar = refresh ? '?show-selected-vendors-snackbar=true' : '';
      navigateTo('/consent-notices/vendors-list/' + templateId + snowSnackbar);
      setWatchingSavingTemplateLoading(false);
    },
    [navigateTo, templateId],
  );

  useEffect(() => {
    // not testable
    /* istanbul ignore next */
    if (searchParams?.get('fromPath')) {
      searchParams.delete('fromPath');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const { data: { data: listProperties } = {}, isLoading: isLoadingProperties, error } = usePropertiesWithVendorCounts();
  // not testable
  /* istanbul ignore next */
  const changeStep = (value: number) => {
    if (!propertyIds.length) return setIsOpen(true);
    searchParams.delete('search');
    searchParams.delete('page');
    setSearchParams(searchParams);
    setActiveStep(value);
  };

  const handleAddVendors = () => {
    updateTemplateConfig(updatedConfig);
    setWatchingSavingTemplateLoading(true);
  };

  useEffect(() => {
    if (!watchingSavingTemplateLoading) return;
    if (savingTemplateConfig) return;
    if (!savingTemplateConfig && watchingSavingTemplateLoading) {
      setWatchingSavingTemplateLoading(false);
      handleBackButton(true);
    }
  }, [watchingSavingTemplateLoading, savingTemplateConfig, setWatchingSavingTemplateLoading, handleBackButton]);

  useEffect(() => {
    if (isOpenSaveModal === true) {
      setUpdateConfig(prepareTemplateConfig({ addVendorsConfig, vendors: vendorsFromPropertyIds, templateConfig }));
    }
  }, [isOpenSaveModal, setUpdateConfig, addVendorsConfig, vendorsFromPropertyIds, templateConfig, allAvailableVendors]);

  const handleOpenSaveModal = () => {
    setUpdateConfig(prepareTemplateConfig({ addVendorsConfig, vendors: vendorsFromPropertyIds, templateConfig }));
    if (addVendorsConfig.addMethod === 'merge') {
      return setIsOpenSaveModalmerge(true);
    } else {
      return setIsOpenSaveModal(true);
    }
  };

  const managedVendors = vendorsFromPropertyIds?.filter(vendor => {
    return vendor.partner_id && addVendorsConfig?.addedVendors?.includes(vendor.functional_namespace);
  });

  const validationModalText = useMemo(() => {
    return getModalTextDependingOnSaveActions({ addVendorsConfig, updatedConfig, managedVendors });
  }, [addVendorsConfig, updatedConfig, managedVendors]);

  const allHostsFromVendors = useMemo(() => {
    return Array.from(new Set(allAvailableVendors.flatMap(vendor => vendor.hosts || [])));
  }, [allAvailableVendors]);

  const [isSnackBarShown, setIsSnackBarShown] = useState(true);
  const handleCloseSnackBar = () => {
    setIsSnackBarShown(false);
  };
  const isLoadingActions = watchingSavingTemplateLoading || savingTemplateConfig;

  return (
    <section className=" h-full box-border !flex flex-col relative">
      <VendorsProvider allHostsFromVendors={allHostsFromVendors}>
        {isSnackBarShown && !isLoadingProperties && !isLoadingVendors && activeStep === 1 && (
          <DidomiSnackbar
            style={{ position: 'fixed', bottom: '60px', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '6' }}
            action-name="Close"
            onActionClick={handleCloseSnackBar}
            variant="error"
            text={`${vendorsFromPropertyIds.filter(vendor => !vendor.partner_id)?.length} unmatched vendors. Please match these vendors before continuing.`}
          ></DidomiSnackbar>
        )}
        <DidomiModal permanent={true} isOpen={isOpenSaveModaMerge}>
          <DidomiModalHeader modalSubTitle={validationModalText.title} variant="warning"></DidomiModalHeader>
          <p style={{ fontSize: '14px', marginTop: '14PX' }} className="text-primary-blue-6">
            These vendors <strong>{validationModalText.descMethod}</strong> to the current selection. <br />
            {validationModalText.desc}
          </p>
          <div className="flex mt-m">
            <DidomiButton
              style={{ marginRight: '12px' }}
              variant="secondary"
              onClick={() => {
                setIsOpenSaveModalmerge(false);
              }}
            >
              Cancel
            </DidomiButton>
            <DidomiButton onClick={() => handleAddVendors()}>Merge & Save</DidomiButton>
          </div>
        </DidomiModal>
        <DidomiModal permanent={true} isOpen={isOpenSaveModal}>
          <DidomiModalHeader modalSubTitle={validationModalText.title} variant="warning"></DidomiModalHeader>
          <p style={{ fontSize: '14px', marginTop: '14PX' }} className="text-primary-blue-6">
            These vendors <strong>{validationModalText.descMethod}</strong> the current selection <br />
            {validationModalText.desc}
          </p>
          <div className="flex mt-m">
            <DidomiButton
              style={{ marginRight: '12px' }}
              variant="secondary"
              onClick={() => {
                setIsOpenSaveModal(false);
              }}
            >
              Cancel
            </DidomiButton>
            <DidomiButton onClick={() => handleAddVendors()}>Overwrite & Save</DidomiButton>
          </div>
        </DidomiModal>
        <DidomiModal permanent={true} isOpen={isOpen}>
          <DidomiModalHeader
            illustrationName="warning-modal"
            // modalTitle={'Select domains from the list'}
            modalSubTitle={'You need to select some domains from the list first.'}
            modalDescription={'If the domain you need is not in the list, go to the Compliance Report section in order to add it.'}
            variant="warning"
          ></DidomiModalHeader>
          <div className="flex mt-s">
            <DidomiButton
              className="mr-xs"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Ok
            </DidomiButton>
          </div>
        </DidomiModal>
        <DidomiSkeleton variant="layout" isLoading={loadingTemplate || loadingTemplateConfig}>
          <div>
            <DidomiSecondaryHeader title-text="CMP Vendors sync" actions-in-center>
              <div slot="back-button">
                <DidomiBackButtonLink data-testid="back-btn" className="cursor-pointer" onClick={() => handleBackButton(false)}></DidomiBackButtonLink>
              </div>
              <div slot="description">{template?.name || ''}</div>
              <div slot="actions">
                {activeStep !== 0 && (
                  <DidomiButton disabled={isEmptyIabVendors || !!error || isLoadingActions} onClick={() => handleOpenSaveModal()}>
                    {'Add these vendors'}
                  </DidomiButton>
                )}
                {activeStep === 0 && (
                  <DidomiButton className="flex items-center" onClick={() => changeStep(1)}>
                    Next
                    <DidomiIcon name="right-on" className="ml-xxs"></DidomiIcon>
                  </DidomiButton>
                )}
              </div>
            </DidomiSecondaryHeader>
            <DidomiHintbox title-text="Choose one or more domains (step 1). Then choose the vendors to be added to your vendor list (step 2)." className="mt-s">
              <div>
                {
                  'The compliance monitoring checks for all active vendors. If one of your domains is missing from the list below, please ensure it is added in your compliance monitoring.'
                }
              </div>
            </DidomiHintbox>
          </div>
          {cloneElement(children, {
            activeStep,
            setActiveStep,
            toogleValidationModal: setIsOpen,
            setAddVendorsConfigs,
          })}

          {(listProperties && listProperties.length === 0) || !!error ? (
            ''
          ) : (
            <>
              <DidomiBottomBar className="-mx-l -mb-m flex-shrink-0 h-[55px]" isOpen={true} variant="light" closable={false} style={{ opacity: isLoadingProperties ? '0' : '1' }}>
                <div slot="actions" className="flex items-center gap-4 mt-xs">
                  {activeStep !== 0 && (
                    <DidomiButton disabled={isEmptyIabVendors || !!error || isLoadingActions} onClick={() => handleOpenSaveModal()}>
                      {'Add these vendors'}
                    </DidomiButton>
                  )}
                  {activeStep === 0 && (
                    <DidomiButton data-testId="step1" className="flex items-center" onClick={() => changeStep(1)}>
                      Next
                      <DidomiIcon name="right-on" className="ml-xxs"></DidomiIcon>
                    </DidomiButton>
                  )}
                </div>
              </DidomiBottomBar>
            </>
          )}
        </DidomiSkeleton>
      </VendorsProvider>
    </section>
  );
};
