import React, { createContext, useContext, useMemo, useCallback } from 'react';

interface VendorsContextType {
  hasHost: (hostId: string) => boolean;
}

const VendorsContext = createContext<VendorsContextType | undefined>(undefined);

export const useVendorsContext = () => {
  const context = useContext(VendorsContext);
  if (context === undefined) {
    throw new Error('useVendorsContext must be used within a VendorsProvider');
  }
  return context;
};

export const VendorsProvider: React.FC<{ children: React.ReactNode; allHostsFromVendors: string[] }> = ({ children, allHostsFromVendors }) => {
  const hasHost = useCallback(
    (hostId: string): boolean => {
      return allHostsFromVendors.includes(hostId);
    },
    [allHostsFromVendors],
  );

  const value = useMemo(() => ({ hasHost }), [hasHost]);

  return <VendorsContext.Provider value={value}>{children}</VendorsContext.Provider>;
};
